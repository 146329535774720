import React from 'react'
import pic3 from "../assets/pic3.png";
import pic9 from "../assets/pic9.png";
import pic10 from "../assets/pic10.png";
import pic11 from "../assets/pic11.png";
import pic12 from "../assets/pic12.png";
import pic13 from "../assets/pic13.png";
import pic14 from "../assets/pic14.png";

const steps = [
    {
        title: "Add Tasks To Your Notion",
        description: "Once you're onboard, you'll get access to your own Notion Board. Here, you or any of your team members can add tasks related to design, development, or strategy. Track progress in real-time and watch as your project comes to life on Figma, with live status updates directly in Notion.",
        image: pic10,
        upArrow: pic14
    },
    {
        title: "Relax or Reach Out Anytime",
        description: "Our team collaborates seamlessly on Slack, cutting down on the need for long Zoom meetings or endless email chains. We're always just a text away, ready to assist or provide updates whenever you need.",
        image: pic11,
        downArow: pic13
    },
    {
        title: "Review & Refine Your Project",
        description: "Once we complete a task, the results are uploaded to Figma for your review. You'll have plenty of time to go through the designs, drop comments, and request any changes—all well before your deadline, ensuring a smooth, stress-free process.",
        image: pic12,
    }
];

const HowWeWork = () => {
  return (
    <div id="howWeWork" className="bg-[#050A11] w-full">
        <div className='flex md:hidden justify-center items-center mb-10'>
        <img src={pic9} alt="pic9" />
      </div>
    <div className="flex flex-col justify-center items-start max-w-[100rem] mx-auto gap-20 px-10 xl:px-20 pb-5">
        <div className="flex justify-center lg:justify-between items-center gap-96">
            <div className="flex flex-col justify-center items-start gap-8 text-white">
                <div className='flex flex-col gap-3'>
                    <p className="text-4xl xl:text-7xl font-semibold max-w-2xl">How Do We Work</p>
                    <p className="text-4xl xl:text-7xl font-semibold max-w-2xl">Exactly</p>
                </div>
                {/* <p className="text-4xl xl:text-7xl font-semibold max-w-2xl">How Do We Work Exactly</p> */}
                <p className="text-lg font-semibold max-w-xl poppins">Its Simple & Streamlined. Here's How:</p>
            </div>
            <div className="hidden lg:inline-flex ripple">
                <img src={pic3} alt="orange" />
            </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 xl:justify-start items-start gap-20 poppins">
            {steps.map((step, index) => {
                return (
                    <div key={index} className="relative flex flex-col justify-center items-start gap-5">
                        <img className='absolute -top-8 left-0' src={step.image} alt="numberImage" />
                        {step.upArrow && (
                            <img className='hidden lg:block absolute -top-10 -right-16' src={step.upArrow} alt="upArrow" />
                        )}
                        {step.downArow && (
                            <img className='hidden lg:block absolute -bottom-10 -right-16' src={step.downArow} alt="downArrow" />
                        )}
                        <div className="flex justify-start items-center gap-3">
                            <i className="pi pi-file text-orange-400"></i>
                            <p className="text-lg font-semibold text-orange-400">{step.title}</p>
                        </div>
                        <p className="text-sm max-w-sm text-white font-thin">{step.description}</p>
                    </div>
                )
            })}
        </div>
    </div>
    </div>
  )
}

export default HowWeWork
