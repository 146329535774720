import React, { useEffect, useRef, useState } from 'react'
import Navbar from '../Components/Navbar'
import Hero from '../Components/Hero'
import HowWeWork from '../Components/HowWeWork'
import OurServices from '../Components/OurServices'
import WhatWeHaveCreated from '../Components/WhatWeHaveCreated'
import OurApproach from '../Components/OurApproach'
import Deliverables from '../Components/Deliverables'
import Testimonials from '../Components/Testimonials'
import FAQs from '../Components/FAQs'
import ReadyToWorkWithUs from '../Components/ReadyToWorkWithUs'
import Footer from '../Components/Footer'
import CalendlyWidget from '../Components/CalendlyWidget'
import { useLocation } from 'react-router-dom'

const Home = ({ setTitle }) => {
  const location = useLocation();

  useEffect(() => {
    if(location.hash) {
      const element = document.querySelector(location.hash);
      if(element) {
        element.scrollIntoView({ behavior: "smooth" })
      }
    }
  },[location]);

  useEffect(() => {
    setTitle("ByteOrange - Zest Your Digital Experience")
  },[])

  return (
    <div className='app inter w-full overflow-x-hidden'>
      <Navbar />
      <Hero />
      <HowWeWork />
      <OurServices />
      <WhatWeHaveCreated />
      <OurApproach />
      <Deliverables />
      <Testimonials />
      <FAQs />
      <ReadyToWorkWithUs />
      <CalendlyWidget />
      <Footer />
    </div>
  )
}

export default Home
