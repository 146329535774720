import React, { useEffect } from 'react'
import contactImage from "../assets/bg-contact.png";
import pic1 from "../assets/pic1.png";
import CalendlyWidget from './CalendlyWidget';
import { InlineWidget } from 'react-calendly';
import { useNavigate } from 'react-router-dom';

const Contact = ({ setTitle }) => {
    const navigate = useNavigate();

    const goToHome = () => {
        navigate("/");
    }

    useEffect(() => {
        setTitle("Contact | ByteOrange");
    },[]);

  return (
    <div className='bg-hero-three bg-contain bg-center bg-no-repeat h-full w-full inter relative overflow-x-hidden'>
        <div className='flex flex-col items-start'>
        <div className="flex justify-between items-start z-20 py-10 px-10 xl:px-20">
            <div className="flex flex-col justify-center items-start gap-10 text-white">
                <h2 className="text-5xl font-semibold"><i className='pi pi-arrow-left text-2xl hover:cursor-pointer' onClick={goToHome}></i> Contact Us</h2>
                <div className="flex flex-col justify-center items-start gap-3 poppins">
                    <div className="flex justify-start items-center gap-3">
                        <i className='pi pi-phone'></i>
                        <p className='underline underline-offset-4'>+91 9661-796-214</p>
                    </div>
                    <div className="flex justify-start items-center gap-3">
                        <i className='pi pi-envelope'></i>
                        <p className='underline underline-offset-4'>reachus@byteorange.com</p>
                    </div>
                </div>
                <div className='flex flex-col sm:flex-row justify-start items-center gap-10 sm:gap-20 poppins'>
                    <div className='border-b-[0.5px] border-gray-400 p-1'>
                        <input className='bg-[#050A11] border-none border-b-2 inter' type="text" placeholder='Name' />
                    </div>
                    <div className='border-b-[0.5px] border-gray-400 p-1'>
                        <input className='bg-[#050A11] border-none border-b-2 inter' type="text" placeholder='Company Name' />
                    </div>
                </div>
                <div className='border-b-[0.5px] border-gray-400 p-1 w-full poppins'>
                    <input className='bg-[#050A11] border-none border-b-2 inter' type="text" placeholder='Contact Number' />
                </div>
                <div className='border-b-[0.5px] border-gray-400 p-1 w-full poppins'>
                    <input className='bg-[#050A11] border-none border-b-2 inter' type="text" placeholder='Email' />
                </div>
                {/* <div className='flex flex-col gap-2'>
                    <p className='text-lg font-semibold poppins'>Budget</p>
                    <div className='flex justify-start items-center flex-wrap gap-10 poppins'>
                        <div className='flex justify-center items-center p-4 bg-gray-800 rounded-full'>
                            <p className='text-sm text-gray-500'>Under 50k</p>
                        </div>
                        <div className='flex justify-center items-center p-4 bg-gray-800 rounded-full'>
                            <p className='text-sm text-gray-500'>50k - 100k</p>
                        </div>
                        <div className='flex justify-center items-center p-4 bg-gray-800 rounded-full'>
                            <p className='text-sm text-gray-500'>Open to explore</p>
                        </div>
                    </div>
                </div> */}
                <div className='border-b-[0.5px] border-gray-400 p-1 w-full poppins'>
                    <input className='bg-[#050A11] border-none border-b-2 inter' type="text" placeholder='Message' />
                </div>
                <a href="#calendlyWidget">
                    <button className="bg-orange-600 py-4 px-8 font-semibold hover:cursor-pointer hover:bg-orange-500 flex justify-center items-center gap-3 manrope">
                        <p>Book a free call</p>
                        <div className='p-2 border border-white rounded-full flex justify-center items-center'>
                            <i className='pi pi-phone'></i>
                        </div>
                    </button>
                </a>
                <div className='block lg:hidden'>
                <div id='calendlyWidget' className='bg-[#050A11] w-full py-5'>
                    <InlineWidget url="https://calendly.com/byteorange/book-a-call" />
                </div>
                </div>
                <p className='poppins'>Social Media</p>
                <div className="flex flex-col sm:flex-row justify-start items-center gap-3 poppins">
                    <i className="pi pi-facebook p-2 rounded-full"></i>
                    <i className="pi pi-twitter p-2 rounded-full"></i>
                    <i className="pi pi-linkedin p-2 rounded-full"></i>
                    <i className="pi pi-instagram p-2 rounded-full"></i>
                </div>
            </div>
            </div>
            <div className='lg:block hidden w-full'>
                <div id='calendlyWidget' className='bg-[#050A11] max-w-[100rem] mx-auto py-5'>
                    <InlineWidget url="https://calendly.com/byteorange/book-a-call" />
                </div>
            </div>
        </div>
            <img className='lg:hidden absolute bottom-10 right-1 h-[12rem] -z-10' src={pic1} alt="pic1" />
            <img className='hidden lg:block absolute top-48 right-1 h-[30rem] -z-10' src={pic1} alt="pic1" />
            <img className='absolute bottom-0 xl:top-5 h-screen right-1 -z-20' src={contactImage} alt="contactImage" />
    </div>
  )
}

export default Contact
