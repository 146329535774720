import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import 'primeicons/primeicons.css';

import Home from "./Pages/Home";
import Contact from "./Components/Contact";
import ScrollToTop from "./Components/ScrollToTop";
import TermsAndConditions from "./Components/TermsAndConditions";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import { useEffect, useState } from "react";

function App() {
  const [title, setTitle] = useState("ByteOrange - Zest Your Digital Experience");

  useEffect(() => {
    document.title = title;
  },[title]);

  return (
    <BrowserRouter>
    <ScrollToTop />
      <Routes>
        <Route index element={<Home setTitle={setTitle} />} />
        <Route path="/contact" element={<Contact setTitle={setTitle} />} />
        <Route path="/termsAndConditions" element={<TermsAndConditions setTitle={setTitle} />} />
        <Route path="/privacyPolicy" element={<PrivacyPolicy setTitle={setTitle} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
