import React, { useState } from 'react'
import logo from "../assets/logo.png";
import footerLogo from "../assets/footerLogo.png";
import { Link, Outlet } from 'react-router-dom';

const DifferentPagesNavbar = () => {
    const [visible, setVisible] = useState(false);
    
    const handleVisible = () => {
      setVisible(!visible);
    }
  return (
    <div className='p-3 lg:p-7 bg-[#050A11] lg:bg-gradient-to-r from-[#050A11] from-5% via-[#030f2c] via-30% to-[#050A11] to-100%'>
        <ul className='hidden lg:flex justify-end items-center gap-10 px-12 text-white font-semibold text-sm'>
            <li className='hover:bg-orange-600 p-2'><a href="/">HOME</a></li>
            <li className='hover:bg-orange-600 p-2'><a href="/#howWeWork">HOW WE WORK</a></li>
            <li className='hover:bg-orange-600 p-2'><a href="/#ourServices">OUR SERVICES</a></li>
            <li className='hover:bg-orange-600 p-2'><a href="/#faqs">FAQS</a></li>
            <li className='hover:bg-orange-600 p-2'><a href="/#calendlyWidget">BECOME AN AFFILIATE</a></li>
            <li className='hover:bg-orange-600 p-2'><Link to="/contact">CONTACT</Link></li>
        </ul>
        <div className="flex lg:hidden justify-between items-center">
          <img className="w-24" src={footerLogo} alt="footerLogo" />
          <i className="pi pi-align-right text-orange-600 text-2xl hover:cursor-pointer" onClick={handleVisible}></i>
        </div>
        {visible && (
          <ul className="absolute top-0 left-0 w-full flex flex-col justify-center items-start gap-10 bg-[#050A11] text-white z-10 py-3 px-5 inter">
            <i className="pi pi-times text-lg hover:cursor-pointer mt-2" onClick={handleVisible}></i>
            <li><a href="/">HOME</a></li>
            <li><a href="/#howWeWork">HOW WE WORK</a></li>
            <li><a href="/#ourServices">OUR SERVICES</a></li>
            <li><a href="/#faqs">FAQS</a></li>
            <li><a href="/#calendlyWidget">BECOME AN AFFILIATE</a></li>
            <li><Link to="/contact">CONTACT</Link></li>
        </ul>
        )}
        <Outlet />
    </div>
  )
}

export default DifferentPagesNavbar
