import React, { useEffect } from 'react'
import logo from "../assets/logo.png";
import { Link, Outlet } from 'react-router-dom';
import DifferentPagesNavbar from './DifferentPagesNavbar';
import DifferentPagesFooter from './DifferentPagesFooter';

const items = [
    {
        title: "Intellectual Property Rights",
        info: "Unless otherwise indicated, the Website is the property of ByteOrange and all source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics of any nature and regardless of format (herein, collectively or individually, the “Content”) and the trademarks, service marks, and logos contained there (the “Marks”) are owned and controlled by ByteOrange and are protected by copyright and trademark laws and any other applicable intellectual property laws or regulations of India, foreign jurisdictions, and international conventions. The Content and Marks are provided “As-Is” for your information and personal use only. Except as expressly provided herein, no part of the Website and no Content may be copied, reproduced, aggregated, republished, uploaded, posted, displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without ByteOrange's express prior written permission. ByteOrange reserves all rights in the Website, Content, and Marks."
    },
    {
        title: "Ownership of Materials",
        info: "Notwithstanding ByteOrange's ownership of Submissions, as described in Paragraph 6 (“Client Feedback”), all design and original source files created on Client's behalf (“Projects”) belong to Client, and Client shall be the sole owner of the copyright for all Projects. In the event that any operation of law would cause ByteOrange to become the owner of a Project, in whole or in part, rather than Client, ByteOrange irrevocably and perpetually assigns its entire interest in the Project to Client, without limitation. Client warrants that any and all materials provided to ByteOrange as examples or as material to be incorporated into a project during the design process are owned by Client and do not infringe on or misappropriate any third party's rights, including, but not limited to, all intellectual property rights and any right of publicity. ByteOrange always reserves the right to share the Client's design work publicly (social media, website, etc.) unless otherwise agreed upon as stated in section 18 of this document."
    },
    {
        title: "Third-Party Fonts",
        info: "In the event that any Project incorporates fonts that are not owned by ByteOrange and require a commercial license in order for Client to legally reproduce, distribute, or publicly display the Project (“Third-Party Font(s)”), ByteOrange will inform Client in writing that one or more Third-Party Fonts have been incorporated into the Project and that Client will need to purchase one or more licenses for the Third-Party Fonts from the rights-holder(s) of said Third-Party Fonts in order to legally reproduce, distribute, or publicly display the Project. Said notice will include information sufficient for Client to identify which licenses are required and who to contact in order to purchase said licenses. So long as ByteOrange has informed Client of the incorporation of Third-Party Fonts as described above, Client assumes all responsibility for any consequences as a result of a failure by Client to purchase one or more licenses for any Third-Party Fonts incorporated into a Project."
    }
]

const itemsOne = [
    {
        title: "Client Feedback",
        info: "Client acknowledges and agrees that any questions, comments, suggestions, or other feedback (each a “Submission”) shall be the sole property of ByteOrange. ByteOrange may use and disseminate a Submission for any lawful purpose without permission or compensation to Client."
    },
    {
        title: "Management and Oversight",
        info: "ByteOrange reserves the right to monitor and restrict access to the Website for violations of these Terms of Use"
    },
    {
        title: "Privacy Policy",
        info: "By using the Website, Client agrees to the Privacy Policy provided on the Website."
    },
    {
        title: "Returns and Refunds",
        info: "Due to the nature of the service, no refunds are allowed or granted. For cancellation inquiries, contact ByteOrange at reachus@byteorange.com."
    },
    {
        title: "Modification",
        info: "ByteOrange reserves the right to change or discontinue the Website without notice."
    },
    {
        title: "Connection Interruptions",
        info: "ByteOrange does not guarantee uninterrupted Website access."
    },
    {
        title: "Governing Law",
        info: "These Terms of Use shall be governed by and construed in accordance with the laws of Uttar Pradesh, India."
    },
    {
        title: "Litigation",
        info: "All disputes shall be resolved in the courts of Maharashtra, India."
    },
    {
        title: "Disclaimer",
        info: "The Website is provided “As-Is,” and ByteOrange disclaims all warranties."
    },
    {
        title: "Limitations of Liability and Indemnification",
        info: "ByteOrange is not liable for any damages arising from Website use. Client agrees to indemnify ByteOrange against any claims or damages."
    },
    {
        title: "User Data",
        info: "ByteOrange is not responsible for loss or corruption of user data."
    },
    {
        title: "Electronic Communications and Signatures",
        info: "Client agrees to electronic communications, transactions, and signatures."
    },
    {
        title: "Showcasing Design Work",
        info: "ByteOrange reserves the right to showcase design work publicly unless otherwise agreed upon in writing."
    },
    {
        title: "Referrals",
        info: "Referral tracking and payouts are currently handled manually."
    },
    {
        title: "Miscellaneous",
        info: "These Terms of Use constitute the entire agreement between ByteOrange and the Client"
    },
]

const TermsAndConditions = ({ setTitle }) => {
    useEffect(() => {
        setTitle("Terms and Conditions | ByteOrange");
    },[])

  return (
    <>
    <DifferentPagesNavbar />
    <div className='flex flex-col p-5 gap-20 text-white inter'>
      <div className='flex flex-col gap-8'>
        <h2 className='text-3xl font-bold text-orange-500'>Terms of Usage - ByteOrange</h2>
        <p>THIS TERMS OF USE AGREEMENT (the “Agreement”) constitutes a legally binding agreement by and between ByteOrange, a company organized under the laws of India (“ByteOrange”) & registered in India abiding by Indian Laws, and the client, whether personally or on behalf of an entity (“Client”), with regard to access and use of ByteOrange's website: (https://www.byteorange.com) (“Website”) and any other media form, channel, mobile website, or mobile application related, linked, or otherwise connected thereto. Failure to agree and adhere to all of the terms, conditions, and obligations contained herein results in the express prohibition of the Client's use of the Website, and the Client is ordered to discontinue use immediately. Thereafter, the relationship between Client and ByteOrange shall cease and be of no further force and effect between the parties, except that any obligation of Client to pay ByteOrange for services rendered shall remain and continue to be an ongoing obligation owed by Client to ByteOrange.</p>
        {items.map((item, index) => {
            return (
                <div key={index} className='flex flex-col gap-1'>
                    <p className='text-lg font-bold text-orange-500'>{index + 1}. {item.title}</p>
                    <p>{item.info}</p>
                </div>
            )
        })}
        <div className='flex flex-col gap-1'>
                    <p className='text-lg font-bold text-orange-500'>4. User Representations</p>
                    <p>By using the Website, Client represents and warrants that:</p>
                    <ul className='ml-5'>
                        <li className='list-disc'>Client has the legal capacity and agrees to comply with these Terms of Use.</li>
                        <li className='list-disc'>Client is not a minor in the jurisdiction of their domicile</li>
                        <li className='list-disc'>Client will not access the Website through automated or non-human means.</li>
                        <li className='list-disc'>Client will not use the Website for any illegal or unauthorized purpose.</li>
                        <li className='list-disc'>Client's use of the Website will not violate any applicable law or regulation.</li>
                    </ul>
                </div>
                <div className='flex flex-col gap-1'>
                    <p className='text-lg font-bold text-orange-500'>5. Prohibited Activities</p>
                    <p>Client shall not access or use the Website for any purpose other than that for which the Website is made available. Further, Client agrees to refrain from the following:</p>
                    <ul className='ml-5'>
                        <li className='list-disc'>Make any unauthorized use of the Website.</li>
                        <li className='list-disc'>Retrieve data or content for the purposes of creating or compiling a database or directory.</li>
                        <li className='list-disc'>Circumvent, disable, or interfere with security-related features.</li>
                        <li className='list-disc'>Trick, defraud, or mislead ByteOrange or other users.</li>
                        <li className='list-disc'>Interfere with, disrupt, or create an undue burden on the Website.</li>
                        <li className='list-disc'>Use the Website in an effort to compete with ByteOrange.</li>
                        <li className='list-disc'>Decipher, decompile, or reverse engineer any part of the Website.</li>
                        <li className='list-disc'>Harass or threaten ByteOrange employees, independent contractors, or agents.</li>
                        <li className='list-disc'>Upload or transmit viruses, Trojan horses, or any harmful material.</li>
                        <li className='list-disc'>Use the Website in a manner inconsistent with applicable laws.</li>
                    </ul>
                </div>
                {itemsOne.map((item, index) => {
                    return (
                        <div key={index} className='flex flex-col gap-1'>
                            <p className='text-lg font-bold text-orange-500'>{index + 6}. {item.title}</p>
                            <p>{item.info}</p>
                        </div>
                    )
                })}
      </div>
      <Outlet />
    </div>
    <DifferentPagesFooter />
                </>
  )
}

export default TermsAndConditions
