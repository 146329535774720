import React from 'react'
import pic2 from "../assets/pic2.png";
import pic8 from "../assets/pic-8.png";
import { PopupButton, PopupWidget } from "react-calendly";
import { useNavigate } from 'react-router-dom';
import CountUp from 'react-countup';
import ProgressBar from './ProgressBar';

const Hero = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-[#050A11] bg-hero-one bg-cover bg-center bg-no-repeat h-full w-full">
      <div className="relative flex flex-col xl:flex-row justify-center items-center xl:items-start max-w-[100rem] mx-auto gap-16 px-5 xl:px-20 pt-10">
        <div className="flex flex-col justify-center items-start gap-8 text-white pt-12">
          <div className='flex flex-col gap-3'>
            <p className="text-6xl xl:text-6xl font-semibold">Zest Your Digital</p>
            <p className="text-6xl xl:text-6xl font-semibold">Experience with</p>
            <p className="text-6xl xl:text-6xl font-semibold text-orange-600">ByteOrange</p>
          </div>
          {/* <p className="text-6xl xl:text-7xl font-semibold max-w-2xl">Zest Your Digital Experience with <p className="text-orange-600">ByteOrange</p></p> */}
          <p className="text-lg font-semibold max-w-xl poppins">Innovative Solutions for Thinking, Designing, and Launching Exceptional Digital Products</p>
        </div>
        <div className="grid grid-rows-1 lg:grid-rows-2 gap-10 xl:gap-2 pb-28">
          <div className="flex flex-col sm:flex-row justify-start items-center gap-5 w-64 ml-10 xl:ml-0 xl:w-96">
              <img src={pic8} alt="bg-element" className='hidden sm:block' />
              <div className="flex flex-col justify-start items-start gap-5 p-10 bg-white poppins w-80">
                <div className='flex gap-2 text-7xl font-semibold'>
                    <CountUp 
                      end={230}
                      duration={2}
                      delay={2}
                      className='w-32'
                    />
                    <p>+</p>
                </div>
                  <p className="text-gray-500 text-md max-w-xs">projects delivered with results that speak for themselves.</p>
                  <ProgressBar max={75} speed={60} />
                  {/* <div className="bg-gradient-to-r from-sky-600 from-75% to-gray-200 to-25% w-full h-2"></div> */}
              </div>
          </div>
          <div>
              <img src={pic2} alt="textual pic" />
          </div>
        </div>
        <a href="#calendlyWidget">
          <button className='hidden xl:flex absolute bottom-[22rem] left-20 bg-orange-600 py-4 px-8 hover:cursor-pointer hover:bg-orange-500 justify-center items-center gap-3 manrope font-bold text-white'>
          <p>Book a free call</p>
                        <div className='p-2 border border-white rounded-full flex justify-center items-center'>
                            <i className='pi pi-phone'></i>
                        </div>
          </button>
        </a>
      </div>
    </div>
  )
}

export default Hero
