import React, { useEffect, useState } from 'react'

const ProgressBar = ({ max, speed }) => {
    const [value, setValue] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setValue((prevValue) => {
                if(prevValue >= max) {
                    clearInterval(intervalId);
                    return max;
                }
                else {
                    return prevValue + 1;
                }
            });
        }, speed);
    },[max, speed])

    return (
        <div className='w-full h-5 bg-gray-200'>
            <div className='progress-bar-completed w-[80%] h-full bg-sky-600' style={{ width: `${(value)}%` }}></div>
        </div>
    )
}

export default ProgressBar
