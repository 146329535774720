import React, { useRef } from 'react'
import Stepper from './Stepper';
import pic3 from "../assets/pic3.png";

const OurApproach = () => {
    const stepperRef = useRef(null);

  return (
    <div className="bg-[#050A11] w-full z-20">
        <div className="flex flex-col justify-center items-center xl:items-start max-w-[100rem] mx-auto gap-20 sm:gap-0 px-5 xl:px-20 pb-24 pt-20 lg:pt-20 xl:pt-44 2xl:pt-96 z-20">
            <p className="text-4xl xl:text-5xl text-white font-semibold mb-10">Our Approach</p>
            <div className="hidden xl:flex justify-start items-center gap-10">
                <div className="flex flex-col justify-center items-start gap-16 text-gray-300 mt-32 poppins">
                    <p className="max-w-xs">Design Strategy & Consulting</p>
                    <p className="max-w-xs">Design and Development</p>
                    <p className="max-w-xs">Design and Development</p>
                </div>
                <Stepper />
                <div className="flex flex-col justify-center items-start gap-10 mt-32 poppins text-gray-300">
                    <div className="grid grid-cols-3 gap-10">
                        <div className="bg-gray-800 py-4 px-32 flex justify-center items-center">
                            <p className="text-center text-white text-sm font-medium">Discover</p>
                        </div>
                        <div className="bg-gray-800 py-4 px-32 flex justify-center items-center">
                            <p className="text-center text-white text-sm font-medium">Definition</p>
                        </div>
                        <div className="bg-gray-800 py-4 px-32 flex justify-center items-center">
                            <p className="text-center text-white text-sm font-medium">Research</p>
                        </div>
                        <div className="bg-gray-800 py-4 px-32 flex justify-center items-center">
                            <p className="text-center text-white text-sm font-medium">Wireframes</p>
                        </div>
                        <div className="bg-gray-800 py-4 flex justify-center items-center">
                            <p className="text-center text-white text-sm font-medium">Final Design</p>
                        </div>
                        <div className="bg-gray-800 py-4 flex justify-center items-center">
                            <p className="text-center text-white text-sm font-medium">User Testing</p>
                        </div>
                        <div className="bg-gray-800 py-4 px-32 flex justify-center items-center">
                            <p className="text-center text-white text-sm font-medium">Architecture</p>
                        </div>
                        <div className="bg-gray-800 py-4 px-32 flex justify-center items-center">
                            <p className="text-center text-white text-sm font-medium">Development</p>
                        </div>
                        <div className="bg-gray-800 py-4 px-32 flex justify-center items-center">
                            <p className="text-center text-white text-sm font-medium">Launch</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex xl:hidden justify-start items-center gap-0 text-gray-300">
            <div className="hidden sm:flex flex-col justify-center items-center gap-10">
                <div>
                    <img src={pic3} alt="orange" className="w-[70px]" />
                </div>
                <div className="bg-orange-500 w-20 h-[0.5px] rotate-90"></div>
                <div className='h-4 w-4 bg-orange-500 rounded-full'></div>
                <div className="bg-orange-500 w-20 h-[0.5px] rotate-90"></div>
                <div className='h-4 w-4 bg-orange-500 rounded-full'></div>
                <div className="bg-orange-500 w-20 h-[0.5px] rotate-90"></div>
                <div className='h-4 w-4 bg-orange-500 rounded-full'></div>
            </div>
                <div className="flex flex-col justify-center items-start gap-5 w-full sm:mt-32">
                    <div className="flex flex-col justify-center items-start gap-3">
                        <p className="text-white max-w-xs">Design Strategy & Consulting</p>
                        <div className="flex justify-start items-center gap-1">
                            <div className="bg-gray-800 p-4 flex justify-center items-center">
                                <p className="text-center text-white text-sm font-medium">Discover</p>
                            </div>
                            <div className="bg-gray-800 p-4 flex justify-center items-center">
                                <p className="text-center text-white text-sm font-medium">Definition</p>
                            </div>
                            <div className="bg-gray-800 p-4 flex justify-center items-center">
                                <p className="text-center text-white text-sm font-medium">Research</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col justify-center items-start gap-3">
                        <p className="text-white max-w-xs">Design and Development</p>
                        <div className="grid grid-cols-3 gap-1">
                            <div className="bg-gray-800 p-4 flex justify-center items-center">
                                <p className="text-center text-white text-sm font-medium">Wireframes</p>
                            </div>
                            <div className="bg-gray-800 p-4 flex justify-center items-center">
                                <p className="text-center text-white text-sm font-medium">Final Design</p>
                            </div>
                            <div className="bg-gray-800 p-4 flex justify-center items-center w-32">
                                <p className="text-center text-white text-sm font-medium">User Testing</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col justify-center items-start gap-3">
                        <p className="text-white max-w-xs">Design and Development</p>
                        <div className="flex justify-start items-center gap-1">
                        <div className="bg-gray-800 p-4 flex justify-center items-center">
                            <p className="text-center text-white text-sm font-medium">Architecture</p>
                        </div>
                        <div className="bg-gray-800 p-4 flex justify-center items-center">
                            <p className="text-center text-white text-sm font-medium">Development</p>
                        </div>
                        <div className="bg-gray-800 p-4 flex justify-center items-center">
                            <p className="text-center text-white text-sm font-medium">Launch</p>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default OurApproach
