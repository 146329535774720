import React from 'react'
import { Avatar } from 'primereact/avatar';

const testimonials = [
    {
        title: "When I first approached ByteOrange, I was nervous about finding the right team to handle our vision. But they blew me away with their responsiveness and ability to understand our needs so quickly. Their all-time availability made me feel like I had a team member, not just a vendor. It's rare to find an agency that truly values your project.",
        name: "Sarah Williams",
        description: "Founder of a Tech startup - USA"
    },
    {
        title: "I've worked with other agencies before, but ByteOrange was different. They didn't just take a brief and deliver a product—they collaborated with me every step of the way. The website they built helped the US stand out in a crowded market. These guys know how to get things done, and they do it with flair.",
        name: "Aleksi Roberts",
        description: "CEO of E-commerce brand - NL"
    },
    {
        title: "We needed a team to handle our video production and graphics for our new clothing line, and ByteOrange was the perfect fit. They were always swift to respond and brought fresh, creative ideas to the table. The videos and graphics have been a hit with our customers, and I couldn't be happier with the results.",
        name: "Rhea Shah",
        description: "Owner of Shah's Boutique - IND"
    }
]

const Testimonials = () => {
  return (
    <div className="bg-[#050A11] w-full">
      <div className="flex flex-col justify-center items-center max-w-[100rem] mx-auto gap-20 px-10 xl:px-20 py-36">
        <div className="flex flex-col lg:flex-row justify-between items-center lg:items-start gap-10 w-full">
        <div className='flex flex-col gap-3'>
            <p className="text-4xl font-semibold text-white max-w-2xl">Here's what our clients have to say</p>
            <p className="text-4xl font-semibold text-white max-w-2xl">about their experience with</p>
            <p className='text-4xl font-semibold text-orange-600 max-w-2xl'>ByteOrange</p>
        </div>
            {/* <p className="text-4xl font-semibold text-white max-w-2xl">Here's what our clients have to say about their experience with <p className="text-orange-600">ByteOrange</p></p> */}
            <div className='flex flex-col gap-5'>
                <p className="text-lg text-gray-400 max-w-md poppins">At ByteOrange, we love what we do, and it shows. Our clients trust us as partners who truly care about their goals, and that's what makes all the difference.</p>
            </div>
        </div>
        <div className="grid grid-cols-1 xl:grid-cols-3 xl:justify-start items-start gap-10 poppins w-full">
            {testimonials.map((item, index) => {
                return (
                    <div className="bg-gray-800 flex flex-col justify-between items-start gap-10 p-6 h-full">
                        <p className="text-lg text-white">{item.title}</p>
                        <div className="flex justify-between items-center w-full">
                            <div className="flex justify-start items-center gap-2">
                                <Avatar icon="pi pi-user" size="large" shape="circle" />
                                <div className="flex flex-col justify-center items-start gap-1">
                                    <p className="text-white text-lg font-semibold">{item.name}</p>
                                    <p className="text-white text-sm">{item.description}</p>
                                </div>
                            </div>
                            <div className="bg-orange-600 py-2 px-6 border rounded-3xl">
                                <i className="pi pi-arrow-right"></i>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
      </div>
    </div>
  )
}

export default Testimonials
