import React, { useEffect } from 'react'
import { Link, Outlet } from 'react-router-dom';
import DifferentPagesNavbar from './DifferentPagesNavbar';
import DifferentPagesFooter from './DifferentPagesFooter';

const items = [
    {
        title: "Personal Data",
        info: "Any information relating to an identified or identifiable individual."
    },
    {
        title: "Usage Data",
        info: "Data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (e.g., duration of a page visit)."
    },
    {
        title: "Cookies",
        info: "Small data files stored on your device (computer or mobile device)."
    },
    {
        title: "Data Controller",
        info: "The entity that determines the purposes and means of processing personal data."
    },
    {
        title: "Data Processors (or Service Providers)",
        info: "Third parties that process personal data on behalf of the Data Controller."
    },
    {
        title: "Data Subject (or User)",
        info: "Any individual using our Service who is the subject of Personal Data."
    }
]

const itemsOne = [
    {
        title: "Security of Data",
        info: "The security of your data is important to us. However, remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security."
    },
    {
        title: "Service Providers",
        info: 'We may employ third-party companies and individuals to facilitate our Service ("Service Providers"), provide the Service on our behalf, perform Service-related services, or assist us in analyzing how our Service is used.'
    },
    {
        title: "Analytics",
        info: "We may use third-party Service Providers to monitor and analyze the use of our Service."
    },
    {
        title: "Behavioral Remarketing",
        info: "ByteOrange uses remarketing services to advertise on third-party websites to you after you visited our Service."
    },
    {
        title: "Links to Other Sites",
        info: "Our Service may contain links to other sites that are not operated by us. If you click a third-party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.\nWe have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites."
    },
    {
        title: "Children's Privacy",
        info: 'Our Service does not address anyone under the age of 18 ("Children").'
    },
    {
        title: "Changes to This Privacy Policy",
        info: "TWe may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page."
    },
    {
        title: "Contact Us",
        info: "If you have any questions about this Privacy Policy, please contact us via email at reachus@byteorange.com."
    },
]

const PrivacyPolicy = ({ setTitle }) => {
    useEffect(() => {
        setTitle("Privacy Policy | ByteOrange");
    },[])

  return (
    <>
      <DifferentPagesNavbar />
    <div className='flex flex-col p-5 gap-20 text-white inter'>
      <div className='flex flex-col gap-8'>
        <h2 className='text-3xl font-bold text-orange-500'>Privacy Policy</h2>
        <p className='text-xl'>Last Updated On: 19/02/2024</p>
        <p>ByteOrange ("us", "we", or "our") operates the [https://www.byteorange.com](https://www.byteorange.com) website (hereinafter referred to as the "Service"). This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data. We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy.</p>
        <p className='text-xl font-bold text-orange-500'>Definitions</p>
        {items.map((item, index) => {
            return (
                <div key={index} className='flex justify-start items-center flex-wrap gap-1'>
                    <p className='font-bold'>{index + 1}. {item.title}: </p>
                    <p className='text-sm'>{item.info}</p>
                </div>
            )
        })}
        <h2 className='text-xl font-bold text-orange-500'>Information Collection and Use</h2>
        <p>We collect several types of information for various purposes to provide and improve our Service to you.</p>
        <h2 className='text-xl font-bold text-orange-500'>Types of Data Collected</h2>
        <div className='flex flex-col gap-1'>
                    <p className='text-lg font-bold'>Personal Data:</p>
                    <ul>
                        <li>- Email address</li>
                        <li>- First name and last name</li>
                        <li>- Cookies and Usage Data</li>
                    </ul>
                </div>
                <div className='flex flex-col gap-1'>
                    <p className='text-lg font-bold'>Usage Data:</p>
                    <ul>
                        <li>- Information such as your computer's Internet Protocol address (IP address), browser type, browser version, pages of our Service you visit, the time and date of your visit, time spent on those pages, and other diagnostic data.</li>
                    </ul>
                </div>
                <div className='flex flex-col gap-1'>
                    <p className='text-lg font-bold'>Tracking & Cookies Data:</p>
                    <ul>
                        <li>- We use cookies and similar tracking technologies to monitor activity on our Service and retain certain information.</li>
                    </ul>
                </div>
        <div className='flex flex-col gap-1'>
                    <p className='text-lg font-bold text-orange-500'>Use of Data</p>
                    <p>ByteOrange uses the collected data for various purposes, including but not limited to:</p>
                    <ul className='ml-5'>
                        <li>- Providing and maintaining the Service.</li>
                        <li>- Notifying you about changes to our Service.</li>
                        <li>- Allowing you to participate in interactive features of our Service.</li>
                        <li>- Providing customer care and support.</li>
                        <li>- Monitoring the usage of the Service.</li>
                        <li>- Detecting, preventing, and addressing technical issues.</li>
                    </ul>
                </div>
                <div className='flex flex-col gap-1'>
                    <p className='text-lg font-bold text-orange-500'>Transfer of Data</p>
                    <p>Your information, including Personal Data, may be transferred to — and maintained on — computers located outside your state, province, country, or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.</p>
                    <p>We will take all the steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy, and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place.</p>
                </div>
                <div className='flex flex-col gap-1'>
                    <p className='text-lg font-bold text-orange-500'>Disclosure of Data</p>
                    <p>We may disclose your personal information in good faith, believing that such action is necessary to:</p>
                    <ul className='ml-5'>
                        <li>1. Comply with a legal obligation.</li>
                        <li>2. Protect and defend the rights or property of ByteOrange.</li>
                        <li>3. Prevent or investigate possible wrongdoing in connection with the Service.</li>
                        <li>4. Protect the personal safety of users of the Service or the public.</li>
                        <li>5. Comply with legal requirements from law enforcement agencies.</li>
                    </ul>
                </div>
                {itemsOne.map((item, index) => {
                    return (
                        <div key={index} className='flex flex-col gap-1'>
                            <p className='text-lg font-bold text-orange-500'>{item.title}</p>
                            <p>{item.info}</p>
                        </div>
                    )
                })}
      </div>
      <Outlet />
    </div>
    <DifferentPagesFooter />
                </>
  )
}

export default PrivacyPolicy
