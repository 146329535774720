import React from 'react'

const Video = () => {
  return (
    <div className="bg-gray-700 w-full relative">
        <video src="" className='w-full'></video>
        <button className='absolute -bottom-3 -right-5'>
            <div className='flex justify-center items-center border-8 border-black rounded-full p-8 bg-orange-600'>
                {/* <i className='pi pi-play text-black text-lg'></i> */}
                <div className='w-5 h-5 border-l-[10px] border-l-transparent border-r-[10px] border-r-transparent border-b-[20px] border-black rotate-90'></div>
            </div>
        </button>
    </div>
  )
}

export default Video
