import React, { useState } from 'react'
import { Link } from 'react-router-dom';

const FAQs = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const faqs = [
        {
            question: "How do I get started with ByteOrange?",
            answer: "Easy—just hit that “Book a Free Call” button on our website. Let's chat about your ideas and how we can bring them to life. It's free, no strings attached."
        },
        {
            question: "Why ByteOrange?",
            answer: "We've worked with hundreds of brands globally to create digital products that don't just work—they wow. From design to launch, we deliver solutions that blend function and aesthetics, helping your brand stand out in the digital world."
        },
        {
            question: "What happens after I book a call?",
            answer: "We'll hop on a quick chat, understand your goals, and map out a plan to make it happen. No fluff, no fuss—just a clear roadmap to bring your vision to life."
        },
        {
            question: "Do I need a fully baked idea?",
            answer: "Not at all! Whether you've got a rough concept or a detailed plan, we'll collaborate to shape it into something amazing. Let's add some zest to your digital experience."
        },
    ]

    const handleCurrentIndex = (index) => {
        setCurrentIndex(index);
    }

  return (
    <div id="faqs" className="bg-[#050A11] w-full">
      <div className="flex flex-col lg:flex-row justify-between items-center lg:items-start max-w-[100rem] mx-auto gap-20 px-10 xl:px-20 py-24">
        <div className="flex flex-col justify-center items-start gap-5">
            <p className="text-4xl text-white font-semibold">FAQs</p>
            <p className="text-lg text-gray-400 font-light max-w-md poppins">Got questions? We've got answers. At ByteOrange, we make the process simple and transparent. From getting started to shaping your ideas, our FAQs are here to guide you every step of the way. Let's bring your vision to life—stress-free and with plenty of zest!</p>
            <div className="flex justify-start items-center flex-wrap gap-8">
                <button className="bg-orange-600 py-4 px-8 flex justify-center items-center gap-2 font-semibold hover:cursor-pointer hover:bg-orange-500 manrope">
                    <p>Book a call with an expert</p>
                    <i className='pi pi-arrow-right'></i>
                </button>
                <Link to="/contact"><button className="bg-orange-600 py-4 px-8 font-semibold hover:cursor-pointer hover:bg-orange-500 manrope">
                    Contact Us
                </button></Link>
            </div>
        </div>
        <div className="flex flex-col justify-center items-center lg:items-start gap-10 poppins">
            {faqs.map((item, index) => {
                return (
                    <div className="flex flex-col justify-center items-start gap-5 py-3 border-t-[0.5px] border-gray-400 w-full">
                        <div className="flex justify-between items-center gap-5 w-full">
                            <p className="text-lg font-semibold text-gray-300">{item.question}</p>
                            <i className="pi pi-plus text-gray-300" onClick={() => setCurrentIndex(index)}></i>
                        </div>
                        {currentIndex === index && (
                            <p className="text-md text-gray-400 font-light max-w-md">{item.answer}</p>
                        )}
                    </div>
                )
            })}
        </div>
      </div>
    </div>
  )
}

export default FAQs
