import React from 'react'

const ReadyToWorkWithUs = () => {
  return (
    <div className="bg-[#050A11] w-full hidden xl:block">
        <div className="bg-pic5 h-96 bg-contain bg-center bg-no-repeat flex flex-col xl:flex-row justify-center items-center gap-40">
            <p className="text-4xl xl:text-4xl text-white font-semibold">Ready to work with us ?</p>
            <a href="https://calendly.com/byteorange/book-a-call">
              <button className="bg-orange-600 py-4 px-8 font-semibold hover:cursor-pointer hover:bg-orange-500 manrope">
                  <div className="flex justify-center items-center gap-10">
                      <p>Get Started</p>
                      <i className="pi pi-arrow-right"></i>
                  </div>
              </button>
            </a>
        </div>
    </div>
  )
}

export default ReadyToWorkWithUs
