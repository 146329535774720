import React, { useEffect, useRef, useState } from 'react'
import pic4 from "../assets/pic4.png";
import Video from './Video';
import CountUp, { useCountUp } from 'react-countup';
import { Avatar } from 'primereact/avatar';
import { AvatarGroup } from 'primereact/avatargroup';
import cl1 from "../assets/cl-1.png";
import cl2 from "../assets/cl-2.png";
import cl3 from "../assets/cl-3.png";
import cl4 from "../assets/cl-4.png";

const OurServices = () => {
  const { start, reset, update } = useCountUp({
    ref: "countup-target",
    start: 0,
    end: 100,
    duration: 2,
    enableScrollSpy: false, // Disable default scroll spy behavior
  });

  useEffect(() => {
    const handleScroll = () => {
      const element = document.querySelector("#countup-div");
      if (element) {
        const rect = element.getBoundingClientRect();
        const isVisible = rect.top < window.innerHeight && rect.bottom > 0;

        if (isVisible) {
          start(); // Start the CountUp animation
          window.removeEventListener("scroll", handleScroll); // Remove listener after animation
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [start]);

  return (
    <div id="ourServices" className="bg-[#050A11] bg-hero-two bg-size bg-center bg-no-repeat h-full w-full pt-5">
      <div className="max-w-[100rem] mx-auto">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-20 text-white px-10 xl:px-20 pt-48">
          <div className='flex flex-col gap-3'>
            <p className="text-4xl max-w-md font-semibold">Provide the best service</p>
            <p className="text-4xl max-w-md font-semibold">with out of the box ideas</p>
          </div>
            {/* <p className="text-4xl max-w-md font-semibold">Provide the best service with out of the box ideas</p> */}
            <p className="text-md font-thin poppins text-gray-400">We're ByteOrange. ByteOrange is more than a digital agency. We create digital experiences that connect with people and help businesses grow! Let's zest your digital experience.</p>
        </div>
        <div className="grid grid-cols-1 gap-5 md:flex text-white px-10 xl:px-20 pt-12 pb-48">
          <div className='relative bg-services-pic bg-cover bg-no-repeat bg-center flex flex-col p-8 h-96 w-full md:w-[70%]'>
            <div className='flex flex-col gap-4'>
              <div className='flex gap-2 text-7xl font-semibold' id="countup-div">
                      <p id="countup-target">0</p>
                      <p>+</p>
              </div>
              <div className='absolute bottom-5 left-8 flex justify-start items-start gap-1'>
                  <Avatar image={cl1} size="large" shape="circle" />
                  <Avatar image={cl2} size="large" shape="circle" />
                  <Avatar image={cl3} size="large" shape="circle" />
                  <Avatar image={cl4} size="large" shape="circle" className='border border-gray-500' />
                  <Avatar label='+' size='large' shape='circle' className='bg-black' />
              </div>
              <p className='text-[#878C91] font-medium text-lg poppins'>Valued clients who trust us and love what we do.</p>
            </div>
          </div>
          {/* <img src={pic4} alt="stats" className='w-full md:w-max' /> */}
          <Video />
        </div>
      </div>
    </div>
  )
}

export default OurServices
