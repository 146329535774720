import React from 'react'
import pic3 from "../assets/pic3.png";
import { Divider } from 'primereact/divider';
import { Badge } from 'primereact/badge';

const Stepper = () => {
  return (
    <div className="flex flex-col justify-center items-center gap-10">
        <div>
            <img src={pic3} alt="orange" className="w-[70px]" />
        </div>
        <div className="bg-orange-500 w-20 h-[0.5px] rotate-90"></div>
        <div className='h-4 w-4 bg-orange-500 rounded-full'></div>
        <div className="bg-orange-500 w-20 h-[0.5px] rotate-90"></div>
        <div className='h-4 w-4 bg-orange-500 rounded-full'></div>
        <div className="bg-orange-500 w-20 h-[0.5px] rotate-90"></div>
        <div className='h-4 w-4 bg-orange-500 rounded-full'></div>
    </div>
  )
}

export default Stepper
