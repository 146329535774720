import logo from "../assets/logo.png";
import footerLogo from "../assets/footerLogo.png";
import pic15 from "../assets/pic15.png";
import whatsappLogo from "../assets/wa.png";
import instagramLogo from "../assets/instagram.png";
import linkedinLogo from "../assets/linkedin.png";
import { Link, Outlet } from "react-router-dom";

const DifferentPagesFooter = () => {
  return (
    <div className="bg-[#050A11] w-full">
      <div className="hidden sm:flex flex-col lg:flex-row justify-between items-start lg:items-start max-w-[100rem] mx-auto gap-28 xl:gap-40 px-20 py-24">
        <div className="flex flex-col justify-center items-start gap-10 xl:gap-5">
          <img src={footerLogo} alt="footerLogo" className="w-40" />
          <div className="flex flex-col md:flex-row justify-center items-start gap-20">
            <div className="flex flex-col justify-center items-start gap-5">
              <p className="text-lg font-semibold text-orange-500 poppins">Contact</p>
              <div className="flex flex-start items-center gap-3">
                  <i className="pi pi-phone text-blue-600"></i>
                  <p className="text-lg font-semibold text-gray-400 poppins">+91 9661-796-214</p>
              </div>
              <div className="flex flex-start items-center gap-3">
                  <i className="pi pi-envelope text-blue-600"></i>
                  <p className="text-md text-gray-400 poppins">reachus@byteorange.com</p>
              </div>
              <div className="flex flex-start items-center gap-3">
                  <i className="pi pi-map-marker text-blue-600"></i>  
                  <p className="text-md text-gray-400 poppins">AllSaints, AllSaints Building, Manchester M156BH, UK</p>
              </div>
          </div>
            <div className="flex flex-col justify-center items-start gap-5">
                <p className="text-lg font-semibold text-orange-500">Navigation</p>
                <a href="/">
                  <p className="font-semibold text-gray-400 poppins">Home</p>
                </a>
                <a href="/#howWeWork">
                  <p className="font-semibold text-gray-400 poppins">How We Work</p>
                </a>
                <a href="/#ourServices">
                  <p className="font-semibold text-gray-400 poppins">Services</p>
                </a>
                <a href="/#ourServices">
                  <p className="font-semibold text-gray-400 poppins">Testimonials</p>
                </a>
                <a href="/#faqs">
                  <p className="font-semibold text-gray-400 poppins">FAQ</p>
                </a>
            </div>
      </div>
      <div className="hidden lg:flex justify-start items-center gap-10 text-white mt-40 pt-3 border-t-2 border-t-gray-500">
        <Link to="/privacyPolicy"><p className="text-lg font-bold">Privacy Policy</p></Link>
        <Link to="/termsAndConditions"><p className="text-lg font-bold">Terms of Service</p></Link>
      </div>
        </div>
        <div className="flex flex-col justify-center items-center gap-5">
            <p className="text-3xl text-gray-400 font-bold pb-3 mb-3 border-b-2 border-b-gray-500 w-full text-center">Connect with us</p>
            <a href="http://wa.me/+919661796214">
              <div className="flex justify-center items-center gap-3 border-[1px] border-orange-600 py-2 px-10">
                <img src={whatsappLogo} alt="whatsappLogo" className="w-10" />
                <p className="text-white">Connect with us on WhatsApp</p>
              </div>
            </a>
              <p className="text-sm text-gray-400">Or</p>
            <a href="https://www.instagram.com/byte.orange/">
              <div className="flex justify-center items-center gap-3 border-[1px] border-orange-600 py-2 px-10">
                <img src={instagramLogo} alt="instagramLogo" className="w-10" />
                <p className="text-white">Connect with us on Instagram</p>
              </div>
            </a>
              <p className="text-sm text-gray-400">Or</p>
            <a href="https://www.linkedin.com/company/byteorange">
              <div className="flex justify-center items-center gap-3 border-[1px] border-orange-600 py-2 px-10">
                <img src={linkedinLogo} alt="linkedinLogo" className="w-10" />
                <p className="text-white">Visit our LinkedIn Page</p>
              </div>
            </a>
            <div className="flex justify-center items-center gap-8 pt-3 mt-3 border-t-2 border-t-gray-500 w-full">
            <a href="">
              <i className="pi pi-facebook bg-white p-2 rounded-full"></i>
            </a>
            <a href="https://x.com/byteorangetech">
              <i className="pi pi-twitter bg-white p-2 rounded-full"></i>
            </a>
            <a href="https://www.linkedin.com/company/byteorange">
              <i className="pi pi-linkedin bg-white p-2 rounded-full"></i>
            </a>
            <a href="https://www.instagram.com/byte.orange/">
              <i className="pi pi-instagram bg-white p-2 rounded-full"></i>
            </a>
          </div>
          <div className="lg:hidden flex justify-start items-center gap-10 text-white mt-40 pt-3 border-t-2 border-t-gray-500">
        <Link to="/privacyPolicy"><p className="text-lg font-bold">Privacy Policy</p></Link>
        <Link to="/termsAndConditions"><p className="text-lg font-bold">Terms of Service</p></Link>
      </div>
        </div>
    </div>
    <div className="sm:hidden flex flex-col lg:flex-row justify-between items-start lg:items-start max-w-[100rem] mx-auto gap-10 pt-24">
      <div className="flex justify-start items-center gap-1 px-5">
        <p className="text-5xl text-white font-semibold">Byte</p>
        <p className="text-5xl text-orange-600 font-semibold">Orange</p>
      </div>
      <div className='border-b-[0.5px] flex flex-col gap-1 w-[90%] border-gray-400 p-1 poppins mx-5'>
        <p className="text-white font-medium pb-2">Subscribe to Newsletter</p>
        <input className='bg-[#050A11] border-none border-b-2 inter' type="text" placeholder='Enter your mail id' />
      </div>
      <div className="flex justify-between items-start mt-10 w-full px-5">
        <div className="flex flex-col gap-5">
          <a href="/">
            <p className="font-semibold text-orange-500 poppins">Home</p>
          </a>
          <a href="/#howWeWork">
            <p className="font-semibold text-orange-500 poppins">How We Work</p>
          </a>
          <a href="/#ourServices">
            <p className="font-semibold text-orange-500 poppins">Services</p>
          </a>
          <a href="/#ourServices">
            <p className="font-semibold text-orange-500 poppins">Testimonials</p>
          </a>
          <a href="/#faqs">
            <p className="font-semibold text-orange-500 poppins">FAQ</p>
          </a>
        </div>
        <div className="flex flex-col gap-5 poppins px-5">
            <p className="text-white">Social Media</p>
            <a href="https://www.linkedin.com/company/byteorange/">
              <i className="pi pi-linkedin bg-white p-2 rounded-full"></i>
            </a>
            <a href="https://www.instagram.com/byte.orange">
              <i className="pi pi-instagram bg-white p-2 rounded-full"></i>
            </a>
        </div>
      </div>
      <div className="flex flex-col justify-center mx-auto gap-5">
                  <p className="text-3xl text-gray-400 font-bold pb-3 mb-3 border-b-2 border-b-gray-500 w-full text-center">Connect with us</p>
                  <a href="https://chat.whatsapp.com/FIgxPDzfzdI5W2pfyKOl23">
                    <div className="flex justify-center items-center gap-3 border-[1px] border-orange-600 py-2 px-10 w-full">
                      <img src={whatsappLogo} alt="whatsappLogo" className="w-10" />
                      <p className="text-white">Join our WhatsApp Community</p>
                    </div>
                  </a>
                    <p className="text-sm text-gray-400 text-center">Or</p>
                  <a href="https://www.instagram.com/byte.orange/">
                    <div className="flex justify-center items-center gap-3 border-[1px] border-orange-600 py-2 px-10 w-full">
                      <img src={instagramLogo} alt="instagramLogo" className="w-10" />
                      <p className="text-white">Follow us on Instagram</p>
                    </div>
                  </a>
                    <p className="text-sm text-gray-400 text-center">Or</p>
                  <a href="https://www.linkedin.com/company/byteorange">
                    <div className="flex justify-center items-center gap-3 border-[1px] border-orange-600 py-2 px-10 w-full">
                      <img src={linkedinLogo} alt="linkedinLogo" className="w-10" />
                      <p className="text-white">Visit our LinkedIn Page</p>
                    </div>
                  </a>
                  </div>
      <a href="#calendlyWidget">
      <button className="bg-orange-600 py-4 px-8 mx-5 font-semibold hover:cursor-pointer hover:bg-orange-500 manrope">
                  <div className="flex justify-center items-center gap-10">
                      <p>Book a free call</p>
                      <i className="pi pi-arrow-right"></i>
                  </div>
              </button>
      </a>
      <div className="w-full relative mt-[25rem]">
        <img src={pic15} alt="pic15" className="absolute bottom-0 -right-5" />
        <div className="absolute bottom-5 left-10 flex justify-center items-center gap-10">
          <p className="text-white font-semibold text-xs">© 2024 ByteOrange. All rights reserved.</p>
          <p className="text-white font-semibold text-xs"><Link to="/termsAndConditions">Terms of Service</Link> | <Link to="/privacyPolicy">Privacy Policy</Link></p>
        </div>
      </div>
    </div>
    <Outlet />
    </div>
  )
}

export default DifferentPagesFooter
