import React from 'react'

const deliverables = [
    {
        title: "CMS",
        info: "The system where content gets managed.",
        icon: "pi pi-desktop"
    },
    {
        title: "Web App",
        info: "The apps that run in your web browser.",
        icon: "pi pi-desktop"
    },
    {
        title: "E-Commerce",
        info: "The online shop where you can sell your products.",
        icon: "pi pi-shopping-cart"
    },
    {
        title: "Website",
        info: "Things like your corporate or marketing website.",
        icon: "pi pi-desktop"
    },
    {
        title: "Desktop App",
        info: "The online shop where you can sell your products.",
        icon: "pi pi-desktop"
    },
    {
        title: "Mobile App",
        info: "The online shop where you can sell your products.",
        icon: "pi pi-desktop"
    }
]

const Deliverables = () => {
  return (
    <div className="bg-[#050A11] bg-hero-two bg-size bg-center bg-no-repeat h-full w-full">
      <div className="flex flex-col justify-center items-start max-w-[100rem] mx-auto gap-12 px-2 xl:px-20 py-20">
        <div className='flex flex-col gap-3'>
            <p className="text-4xl font-semibold text-white">Deliverables.</p>
            <p className="text-4xl font-semibold text-blue-600">Including but not limited to.</p>
        </div>
        {/* <p className="text-4xl font-semibold text-white">Deliverables. <p className="text-4xl font-semibold text-blue-600">Including but not limited to.</p></p> */}
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 justify-center items-start lg:items-start gap-5 poppins">
            {deliverables?.map((item, index) => {
                return (
                    <div className="bg-gray-800 flex flex-col justify-center items-start gap-5 p-4">
                        <div className="flex justify-start items-center gap-3">
                            <i className={item.icon + " text-orange-600"}></i>
                            <p className="font-semibold text-orange-600">{item.title}</p>
                        </div>
                        <p className="text-md text-gray-300 font-medium">{item.info}</p>
                    </div>
                )
            })}
        </div>
      </div>
    </div>
  )
}

export default Deliverables
