import React, { useState } from 'react'
import pic16 from "../assets/pic16.png";
import pic17 from "../assets/pic17.png";
import pic1 from "../assets/pic1.png";
import pic20 from "../assets/pic20.png";
import contactImage from "../assets/bg-contact.png";
import Marquee from "react-fast-marquee";

const images = [pic16, pic17, pic20, pic16, pic17, pic20, pic16, pic17, pic20, pic16, pic17, pic20, pic16, pic17, pic20];

const WhatWeHaveCreated = () => {
    const [index, setIndex] = useState(0);
    const [direction, setDirection] = useState("left");

    const increaseIndex = () => {
        setDirection("right");
    }

    const decreaseIndex = () => {
        setDirection("left");
    }

  return (
    <div className="bg-[#050A11] w-full">
        <div className="flex flex-col gap-20 py-5 relative z-20">
            <img src={contactImage} alt="contactImage" className='absolute top-2 -z-10' />
            <div className="relative flex justify-start items-start gap-28 mx-5 xl:mx-20">
                <div className="flex flex-col justify-start items-start gap-5 text-white">
                    <div className='flex flex-col gap-3'>
                        <p className="text-4xl xl:text-7xl font-semibold max-w-lg">What Our</p>
                        <p className="text-4xl xl:text-7xl font-semibold max-w-lg">Portfolio Says</p>
                    </div>
                    {/* <p className="text-4xl xl:text-7xl font-semibold max-w-lg">What We've Created</p> */}
                    <p className="text-xl font-medium max-w-xl poppins text-gray-300">A glimpse of our finest work—designed with creativity, precision, and a focus on exceptional results. While many projects are under confidentiality or whitelabel agreements, we'd be happy to share more on a call. Let's connect and bring your vision to life!</p>
                </div>
                <img src={pic1} alt="pic1" className='hidden lg:block' />
                <div className='absolute bottom-5 right-5'>
                    <div className="hidden xl:flex justify-start items-center gap-5">
                        <i className="p-6 border border-gray-400 rounded-full pi pi-angle-left text-white hover:cursor-pointer" onClick={decreaseIndex}></i>
                        <i className="p-6 border border-gray-400 rounded-full pi pi-angle-right text-white hover:cursor-pointer" onClick={increaseIndex}></i>
                    </div>
                </div>
            </div>
            <div className="flex flex-col justify-center items-center xl:items-end gap-5">
                {/* <div className="hidden xl:flex justify-center items-center mx-auto gap-5"> */}
                    <Marquee direction={direction}>
                        {images.map((image, index) => {
                            return (
                                <img key={index} src={image} alt="projectImage" className='h-96' />
                            )
                        })}
                    </Marquee>
                    {/* <img src={images[index]} alt="projectImage" /> */}
                {/* </div> */}
                {/* <div className='xl:hidden'>
                    <img src={images[index]} alt="projectImage" className='h-56' />
                </div> */}
                <div className="xl:hidden flex justify-start items-center gap-5">
                    <i className="p-6 border border-gray-400 rounded-full pi pi-angle-left text-white hover:cursor-pointer" onClick={decreaseIndex}></i>
                    <i className="p-6 border border-gray-400 rounded-full pi pi-angle-right text-white hover:cursor-pointer" onClick={increaseIndex}></i>
                </div>
            </div>
        </div>
    </div>
  )
}

export default WhatWeHaveCreated
