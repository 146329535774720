import React from 'react'
import { InlineWidget } from 'react-calendly'

const CalendlyWidget = () => {
  return (
    <div id='calendlyWidget'>
      <InlineWidget url="https://calendly.com/byteorange/book-a-call" />
    </div>
  )
}

export default CalendlyWidget
